<template>
  <v-app-bar
    app
    class="appbar"
    clipped-left
    :height="$vuetify.breakpoint.smAndDown ? 143 : 91"
  >
    <v-row no-gutters :align="'center'">
      <v-col
        cols="3"
        sm="4"
        md="1"
        lg="1"
        class="order-0 d-flex justify-start align-center"
      >
        <v-btn
          small
          class="drawer-btn primary rounded"
          @click.prevent.stop="drawerLeft = !drawerLeft"
        >
          <div class="d-flex flex-column justify-space-between">
            <v-icon medium>$menu</v-icon>
            <label class="font-weight-bold mt-2">
              {{ $t("navbar.products") }}
            </label>
          </div>
        </v-btn>
      </v-col>
      <v-col
        cols="5"
        sm="4"
        md="1"
        lg="2"
        class="order-1 justify-center align-center"
      >
        <!-- logo desktop -->
        <router-link :to="{ name: 'Home' }">
          <img
            class="px-0 logo-desktop"
            src="/logo/duplicarclick.svg"
            alt="Logo DupliClick"
            :style="{ height: '60px' }"
          />
          <img
            class="px-0 logo-mobile mx-auto"
            src="/logo/duplicarclick-mobile.svg"
            alt="Logo DupliClick"
            :style="{ height: '60px' }"
          />
        </router-link>
        <!-- logo mobile -->
      </v-col>
      <v-col cols="12" md="5" lg="5" class="order-4 order-md-2 d-flex px-md-4">
        <div class="flex-grow-1 flex-shrink-0 py-2 py-md-0">
          <SearchInput class="pr-auto pr-md-13 pr-lg-auto" />
        </div>
      </v-col>
      <v-col
        cols="4"
        md="5"
        lg="4"
        class="order-2 order-md-3 d-flex justify-end align-center"
      >
        <v-layout v-if="!isAuthenticated" align-center justify-end>
          <v-btn @click.stop="handleLogin()" medium icon class="mr-2">
            <v-icon color="primary">$profile</v-icon>
          </v-btn>
          <v-btn
            @click.stop="handleLogin()"
            medium
            text
            class="d-none d-sm-inline pl-0 font-weight-regular mr-2"
          >
            <span
              v-html="$t('navbar.signup')"
              class="ml-2 text-left text-none"
            ></span>
          </v-btn>
          <v-btn icon @click.stop="handleLogin()">
            <v-icon color="primary">$cart</v-icon>
          </v-btn>
        </v-layout>

        <v-layout v-else justify-end align-center>
          <CartInfoList
            class="info-list d-none d-md-flex bg-transparent"
            v-if="isAuthenticated"
            :isNavbar="true"
          />
          <div class="d-flex align-center mr-2">
            <v-menu bottom offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on.stop="on" medium icon>
                  <v-icon color="primary">$profile</v-icon>
                </v-btn>
              </template>
              <UserMenu :user="cart.user" />
            </v-menu>
            <span
              class="username default--text pl-2"
              v-html="`Ciao,<br/><b>${cart.user.firstName}</b>`"
            ></span>
          </div>
          <div
            class="d-flex align-center rounded-lg border"
            :class="{ disabled: !editableCart }"
          >
            <transition name="bounce" mode="out-in">
              <v-badge
                :content="totalItems"
                :value="totalItems > 0"
                :key="totalItems"
                transition="bounce"
                color="secondary"
                overlap
                offset-x="24"
                offset-y="20"
                class="default--text font-weight-bold"
              >
                <v-btn medium icon @click.stop="openCart()" class="no-border">
                  <v-icon color="primary">$cart</v-icon>
                </v-btn>
              </v-badge>
            </transition>
            <div
              class="cart-total d-none d-sm-flex"
              role="button"
              @click.stop="openCart()"
            >
              <span
                class="text-caption default--text pr-2"
                style="line-height:1"
                v-html="
                  `<b>Carrello</b><br/><b class='primary--text'> ${$n(
                    cart.totalPrice,
                    'currency'
                  )} </b>`
                "
              ></span>
            </div>
          </div>
        </v-layout>
      </v-col>
    </v-row>

    <v-progress-linear
      :active="showOverlay"
      :indeterminate="showOverlay"
      absolute
      bottom
      color="primary"
    ></v-progress-linear>
  </v-app-bar>
</template>
<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: 0.26;
}
.username {
  font-size: 12px;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    display: none;
  }
}
.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}
.v-main {
  background-color: $background-color-white;
  padding-top: calc(90px + env(safe-area-inset-top)) !important;
  // @media #{map-get($display-breakpoints, 'xs-only')} {
  //   padding-top: calc(143px + env(safe-area-inset-top)) !important;
  //   padding-bottom: 80px !important;
  // }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    padding-top: calc(143px + env(safe-area-inset-top)) !important;
    //padding-bottom: $statusbarheight !important;
  }
}
.v-app-bar--fixed {
  background-color: var(--v-grey-lighten3) !important;
  position: fixed;
  top: 0;
  z-index: 11 !important;
  width: 100%;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: fixed;
    top: 0;
    z-index: 11 !important;
    height: $statusbarheightxs;
    width: 100%;
  }
}

.appbar {
  ::v-deep .v-toolbar__content {
    border-top: 11px solid $secondary !important;
  }
}

.drawer-btn {
  height: 60px !important;
  width: 60px !important;
  label {
    font-size: 9px;
  }
}

::v-deep .v-toolbar__content {
  .v-btn--icon {
    border: 1px solid $primary;
    &.no-border {
      border: none;
    }
  }

  .v-badge__badge {
    color: $text-color !important;
  }

  .border {
    border: 1px solid $primary;
  }
}
@media #{map-get($display-breakpoints, 'xs-only')}, #{map-get($display-breakpoints, 'md-and-down')} {
  .logo-desktop {
    display: none;
  }
  .logo-mobile {
    display: flex;
  }
}
@media #{map-get($display-breakpoints, 'sm-only')}, #{map-get($display-breakpoints, 'lg-and-up')} {
  .logo-desktop {
    display: flex;
  }
  .logo-mobile {
    display: none;
  }
}
</style>
<script>
//TODO rivedere completamente la gestione dei breakpoint in modo che non si scassino su telefono....
import login from "~/mixins/login";

import SearchInput from "@/components/navigation/SearchInput";
import CartInfoList from "@/components/cart/CartInfoList";
import UserMenu from "@/components/navigation/UserMenu.vue";

import { forEachCartItem } from "~/service/ebsn";
import { mapActions, mapState } from "vuex";

export default {
  name: "Navbar",
  mixins: [login],
  components: { SearchInput, UserMenu, CartInfoList },
  data() {
    return {
      isMobile: false
    };
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout",
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    goBack() {
      this.$router.go(-1);
    },

    openCart() {
      if (this.editableCart) {
        if (this.isAuthenticated) {
          this.drawerRight = !this.drawerRight;
        } else {
          this.doLogin("OpenCart");
        }
      } else {
        this.handleLogin();
      }
    },
    async handleLogin() {
      if (await this.needLogin("navbar")) {
        if (await this.needAddress()) {
          await this.needTimeslot();
        }
      }
    },
    historyBack() {
      window.history.back();
    }
  },
  computed: {
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.drawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    enableRegistration() {
      return global.config.registration;
    },
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    editableCart() {
      return !(this.$route.name == "Payment");
    },
    showOverlay() {
      return this.$store.state.app.pendingRequests.count > 0;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    }
  }
};
</script>
