<template>
  <v-list
    class="py-3 rounded-sm"
    min-width="248"
    style="border: 1px solid var(--v-primary-base);"
  >
    <v-list-item to="/profile/profile-update">
      <v-list-item-title>{{ $t("profile.menu.profile") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item to="/profile/orders">
      <v-list-item-title>{{ $t("profile.menu.orders") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item to="/profile/lists">
      <v-list-item-title>{{ $t("profile.menu.lists") }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item to="/profile/preferred">
      <v-list-item-title>{{
        $t(
          `${
            user.hasFavorites
              ? "profile.menu.favorite"
              : "profile.menu.bestselling"
          }`
        )
      }}</v-list-item-title>
    </v-list-item>
    <v-divider />
    <v-list-item to="/profile/gifts">
      <v-list-item-title>{{ $t("profile.menu.gifts") }}</v-list-item-title>
    </v-list-item>
    <v-divider />

    <!-- <v-list-item to="/profile/addresses">
                  <v-list-item-title>I miei indirizzi</v-list-item-title>
                </v-list-item> -->
    <!-- <v-list-item to="/profile/news-list">
                  <v-list-item-title>I miei messaggi</v-list-item-title>
                </v-list-item> -->
    <v-list-item @click.stop="onLogoutClick" class="mt-4">
      <v-btn block outlined color="primary">{{
        $t("profile.menu.logout")
      }}</v-btn>
    </v-list-item>
  </v-list>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    user: { type: Object, required: true }
  },
  methods: {
    ...mapActions({
      doLogout: "cart/doLogout"
    }),
    async onLogoutClick() {
      let isLoggedOut = await this.doLogout();
      if (isLoggedOut) {
        if (this.$route.name !== "Home") {
          this.$router.push({ name: "Home" });
        }
      }
    }
  }
};
</script>
